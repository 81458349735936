import { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import LoadingData from '@/components/shared/loading-data'

import Hero from './components/hero'
import HeroWaitlist from './components/hero-waitlist'
import WhyToChoose from './components/why-to-choose'
import GapCta from './components/gap-cta'
import { StepsToFollow } from './components/steps-to-follow'
import { useFeatureFlags } from '@/hooks/use-feature-flags'
import { useUser } from '@/hooks/use-user'
import KycModal from '@/components/kyc-modal'
import useSessionStorage from '@/hooks/useSessionStorage'

import { NOT_KYC_STATUS } from '@/constants/kyc'
import { Partners } from './components/partners'
import ReferEventModal from '../refer-event-modal'

const HomeLandingView = () => {
  const { data: featureFlags } = useFeatureFlags()
  const { data: user } = useUser()
  const [hasShownKycModal, setHasShownKycModal] = useSessionStorage('hasShownKycModal', false)
  const [showKycModal, setShowKycModal] = useState(false)
  const [showReferEventModal, setShowReferEventModal] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const referEventEnabled = process.env.NEXT_PUBLIC_REFER_EVENT_ENABLED === 'true'

  useEffect(() => {
    // Check if KYC modal should be shown
    const shouldShowKycModal = user && NOT_KYC_STATUS.includes(user?.kycStatus) && !hasShownKycModal
    setShowKycModal(shouldShowKycModal)

    // If KYC modal should not be shown, show Refer Event modal instantly
    if (!shouldShowKycModal && user && referEventEnabled) {
      setShowReferEventModal(true)
    }
  }, [user, hasShownKycModal, referEventEnabled])

  const onCloseKycModal = () => {
    setShowKycModal(false)
    setHasShownKycModal(true)
    // Show Refer Event modal after closing KYC modal
    if (referEventEnabled) {
      setShowReferEventModal(true)
    }
  }

  const onCloseReferEventModal = () => {
    setShowReferEventModal(false)
  }

  useEffect(() => {
    if (featureFlags?.join_wait_list_enabled || user) {
      setIsLoading(false)
    }
  }, [featureFlags, user])

  const showHeroWaitlist = featureFlags?.join_wait_list_enabled && !user

  if (isLoading) {
    return <LoadingData />
  }

  return (
    <>
      <Box className="bg-white">
        {showHeroWaitlist ? <HeroWaitlist /> : <Hero />}
        <WhyToChoose />
        <StepsToFollow />
        <Partners />
        <GapCta />
      </Box>
      <KycModal open={showKycModal} setHasShownKycModal={onCloseKycModal} />
      <ReferEventModal open={showReferEventModal} onClose={onCloseReferEventModal} />
    </>
  )
}

export default HomeLandingView
