import 'react-lazy-load-image-component/src/effects/blur.css'
import { Stack } from '@mui/material'

import DoublePointBanner from '@/root/src/components/base/DoublePointBanner'
import Footer from './footer'
import Header from '../components/Header'

export const MainLayout = ({ children }: HocProps) => {
  return (
    <>
      <DoublePointBanner />
      <Stack className="w-full min-h-screen overflow-hidden" sx={{ backgroundColor: '#FFFFFF' }}>
        <Header />
        <Stack className="z-10 overflow-auto flex-1">{children}</Stack>
        <Footer />
      </Stack>
    </>
  )
}
