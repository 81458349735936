import useTrans from '@/hooks/useTrans'
import { IconName, staticImportIcon } from '@/utils/static-import-icon'
import { Stack, Typography } from '@mui/material'
import Image from 'next/image'

function AvailableAt() {
  const trans = useTrans()

  return (
    <Stack
      direction="row"
      alignItems="center"
      className="pl-4 p-1 mt-5 sm:mt-4 border-2 border-dashed border-iw-primary-light rounded-3xl w-full sm:w-fit justify-between"
      gap={2}
    >
      <Typography className="text-sm text-iw-primary font-medium">
        {trans.landing.hero_waitlist.supported_country}
      </Typography>
      <Stack direction="row" alignItems="center" gap={1} className="p-2 bg-transparent rounded-3xl">
        <Image
          alt={IconName.FIAT_VND}
          src={staticImportIcon(IconName.FIAT_VND)}
          width={24}
          height={24}
        />
        <Image
          alt={IconName.FIAT_USD}
          src={staticImportIcon(IconName.FIAT_USD)}
          width={24}
          height={24}
        />
      </Stack>
    </Stack>
  )
}
export default AvailableAt
