import { memo } from 'react'
import { Link, Stack } from '@mui/material'
import { socials as dataSocials } from '@/constants/socials'
import { staticImportIcon } from '@/root/src/utils/static-import-icon'

import Image from 'next/image'

function FooterSocial() {
  const socials = dataSocials(true)
  return (
    <Stack
      data-testid="social-links-wrapper"
      direction="row"
      alignItems="center"
      justifyContent={{ xs: 'start', sm: 'end' }}
      gap={1}
    >
      {socials.map((item, index) => (
        <Link
          key={index}
          href={item.url}
          target="_blank"
          rel="noreferrer"
          className="no-underline bg-iw-primary hover:bg-iw-secondary p-3 rounded-full"
        >
          <Image src={staticImportIcon(item.icon)} alt={item.icon} width={20} />
        </Link>
      ))}
    </Stack>
  )
}

export default memo(FooterSocial)
