import { useCallback } from 'react'

import ArrowUp from '@/assets/svgs/arrow-up.svg'
import Image from 'next/image'

const isBrowser = () => typeof window !== 'undefined'

const ScrollToTopButton = () => {
  const scrollToTop = useCallback(() => {
    if (!isBrowser()) return
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  return (
    <button
      className="p-[0.725rem] w-12 h-12 rounded-full bg-iw-secondary z-[9999]"
      onClick={scrollToTop}
    >
      <Image src={ArrowUp} alt="scroll to top" height={24} />
    </button>
  )
}

export default ScrollToTopButton
