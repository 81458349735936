import React, { useState } from 'react'
import { Button, Container, Dialog, Grid, Stack, Typography } from '@mui/material'
import { IconName, staticImportIcon } from '@/root/src/utils/static-import-icon'

import Image from 'next/image'
import useTrans from '@/hooks/useTrans'
import TransferInput from '@/components/transaction_input'
import HeroWorldMap from '@/assets/hero-world-map.png'
import { useCurrentTransaction } from '@/root/src/providers/transaction'
import YoutubeEmbed from '../../base/YoutubeEmbed'
import useMobileDetection from '@/hooks/use-mobile-detection'
import AvailableAt from './available-at'

const defaultIntroductionVideoURL = 'https://www.youtube.com/embed/MZMHJnXcb0s?si=KLkUR6KbGrn1CndJ'
const introductionVideoURL =
  process.env.NEXT_PUBLIC_INTRODUCTION_VIDEO_URL || defaultIntroductionVideoURL

const Hero = () => {
  const trans = useTrans()
  const [playVideo, setPlayVideo] = useState(false)
  const { storeTransaction } = useCurrentTransaction()
  const isMobile = useMobileDetection()

  return (
    <Container className="relative mb-8 sm:mb-24">
      <Image
        className="absolute mt-10 sm:mt-32 xs:inset-0 right-0 m-auto opacity-20"
        height={391}
        src={HeroWorldMap}
        alt="hero-wait-list"
      />
      <Grid container className="relative text-iw-dark-gray">
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          className="flex flex-col justify-between items-center lg:items-start lg:pt-16 pt-8 gap-4"
        >
          <Stack gap={2} className="relative" alignItems={{ xs: 'center', md: 'start' }}>
            <Typography
              className="font-bold font-ma text-[2rem] text-iw-neutral-black text-center lg:text-left sm:font-extrabold sm:text-5xl lg:leading-[60px] leading-[38px]"
              dangerouslySetInnerHTML={{ __html: trans.landing.hero_waitlist.title(isMobile) }}
            />
            <Typography
              className="text-center lg:text-left text-iw-text-tertiary"
              dangerouslySetInnerHTML={{ __html: trans.landing.hero_waitlist.description }}
            />
            <Button className="p-0" onClick={() => setPlayVideo(true)} disableRipple>
              <Stack direction="row" alignItems="center" gap={2}>
                <Image
                  alt={IconName.PLAYBUTTON}
                  src={staticImportIcon(IconName.PLAYBUTTON)}
                  width={46}
                  height={46}
                />
                <Typography className="text-sm text-iw-charcoal-black font-medium">
                  {trans.landing.hero_waitlist.view_intro}
                </Typography>
              </Stack>
            </Button>
          </Stack>
          <Dialog
            maxWidth={false}
            open={playVideo}
            onClose={() => setPlayVideo(false)}
            data-testid="video-dialog"
          >
            <YoutubeEmbed src={introductionVideoURL} />
          </Dialog>
          {!isMobile && <AvailableAt />}
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TransferInput isLanding handleSubmit={storeTransaction} />
        </Grid>
        {isMobile && <AvailableAt />}
      </Grid>
    </Container>
  )
}

export default Hero
