import React, { memo, useCallback, useContext } from 'react'
import CustomDialog from '@/components/base/CustomDialog'
import { Stack } from '@mui/material'
import Image from 'next/image'
import ReferEventPopupVi from '@/assets/refer-program-event-popup-vi.png'
import ReferEventPopupEn from '@/assets/refer-program-event-popup-en.png'
import { IconName, staticImportIcon } from '@/utils/static-import-icon'
import { useRouter } from 'next/router'
import { MenuProfileContext } from '@/providers/menu-profile'

type ReferEventModalProps = {
  open: boolean
  onClose: () => void
}

const ReferEventModal = (props: ReferEventModalProps) => {
  const { open, onClose } = props
  const { locale } = useRouter()
  const { setIsOpenProfileMenu } = useContext(MenuProfileContext)

  const handleClosePopup = useCallback(() => {
    onClose()
  }, [onClose])

  const handleImageClick = useCallback(() => {
    setIsOpenProfileMenu(true)
    onClose()
  }, [setIsOpenProfileMenu, onClose])

  const imageSource = locale === 'vi' ? ReferEventPopupVi : ReferEventPopupEn

  return (
    <CustomDialog
      handleClose={handleClosePopup}
      isOpened={open}
      maxWidth={false}
      transparentBackground={true}
      bodyDivider={false}
      paperStyle={{
        width: 'auto',
        padding: '5px 8px',
        display: { xs: 'flex', sm: 'block' },
        alignItems: { xs: 'center', sm: 'unset' },
        justifyContent: { xs: 'center', sm: 'unset' },
        transform: { xs: 'translate(0%, -50%)', sm: 'none' },
      }}
      body={
        <Stack direction="row" alignItems="start">
          <Image
            className="hover:cursor-pointer w-[274px] h-[334px] sm:w-[488px] sm:h-[595px]"
            src={imageSource}
            alt="refer-event-popup"
            onClick={handleImageClick}
          />
          <Image
            height={24}
            width={24}
            src={staticImportIcon(IconName.CLOSE_POPUP_ICON)}
            alt={IconName.CLOSE_POPUP_ICON}
            onClick={handleClosePopup}
            style={{ cursor: 'pointer' }}
          />
        </Stack>
      }
    />
  )
}

export default memo(ReferEventModal)
