import { Seo } from '@/components/base'
import Landing from '@/components/landing'
import { SwapProvider } from '@/contexts/swap'
import useTrans from '@/hooks/useTrans'
import { MainLayout } from '@/layouts/MainLayout'
import TransactionProvider from '@/providers/transaction'

const Index = () => {
  const trans = useTrans()

  return (
    <>
      <h1 className="hidden">{trans.meta.home.description}</h1>
      <Seo
        data={{
          title: trans.meta.home.title,
          description: trans.meta.home.description,
          thumbnailUrl: trans.meta.home.thumb,
        }}
      />
      <MainLayout>
        <SwapProvider>
          <TransactionProvider>
            <Landing />
          </TransactionProvider>
        </SwapProvider>
      </MainLayout>
    </>
  )
}

export default Index
