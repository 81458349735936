import { memo } from 'react'
import { Link, Stack } from '@mui/material'
import { IconName, SVGIcon } from '@/root/src/utils/static-import-icon'

export interface ItemProps {
  name: string
  url: string
  icon?: IconName
  onlyIcon?: boolean
}

const FooterItem = (props: ItemProps) => {
  const { name, url, icon, onlyIcon } = props
  const isExternalUrl = url.startsWith('http') || url.startsWith('//')
  return (
    <Link
      data-testid="social-link-discord"
      href={url}
      target={isExternalUrl ? '_blank' : undefined}
      rel={isExternalUrl ? 'noreferrer' : undefined}
      className="text-sm no-underline text-white hover:text-iw-secondary"
    >
      <Stack direction="row" alignItems="center" gap={1}>
        {icon && <SVGIcon iconName={icon} alt={icon} width={32} />}
        {icon && onlyIcon ? '' : name}
      </Stack>
    </Link>
  )
}

export default memo(FooterItem)
