'use client'
import React from 'react'
import { Stack } from '@mui/material'

interface ArrowProps {
  color?: string
}

const ArrowLeft = ({ color = '#1F5F8D' }: ArrowProps) => (
  <svg width="19" height="14" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24 12.001H2.914l5.294-5.295-.707-.707L1 12.501l6.5 6.5.707-.707-5.293-5.293H24v-1z"
      fill={color}
      stroke={color}
      strokeWidth="2"
    />
  </svg>
)

const ArrowRight = ({ color = '#1F5F8D' }: ArrowProps) => (
  <svg width="19" height="14" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 12.001h21.086l-5.294-5.295.707-.707L24 12.501l-6.5 6.5-.707-.707 5.293-5.293H1v-1z"
      fill={color}
      stroke={color}
      strokeWidth="2"
    />
  </svg>
)

const CircleIconButton = ({
  disabled,
  onClick,
  children,
}: {
  disabled: boolean
  onClick: () => void
  children: React.ReactNode
}) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`w-12 h-12 rounded-full flex items-center justify-center transition-colors
      ${disabled ? 'bg-[#EAEAEA] cursor-not-allowed' : 'bg-[#C5DFF2] cursor-pointer'}`}
  >
    {children}
  </button>
)

export const Carousel = ({
  content,
}: {
  content: {
    title: React.ReactNode | any
    description: React.ReactNode | any
    content?: React.ReactNode | any
  }[]
}) => {
  const [currentMobileIndex, setCurrentMobileIndex] = React.useState(0)

  const handlePrevious = () => {
    setCurrentMobileIndex((prev) => (prev > 0 ? prev - 1 : prev))
  }

  const handleNext = () => {
    setCurrentMobileIndex((prev) => (prev < content.length - 1 ? prev + 1 : prev))
  }

  return (
    <Stack className="h-auto min-h-fit lg:h-[50rem] overflow-y-auto flex justify-between relative rounded-md p-0">
      <div className="block lg:hidden w-full h-full flex flex-col">
        <div className="w-full mb-4 overflow-hidden">
          <div
            className="flex transition-transform duration-300 ease-in-out"
            style={{ transform: `translateX(-${currentMobileIndex * 100}%)` }}
          >
            {content.map((item, index) => (
              <div
                key={index}
                className="rounded-2xl w-full flex-shrink-0"
                style={{ width: '100%' }}
              >
                <div className="p-4 rounded-2xl w-full flex-col justify-center bg-white border border-[#DFF1FF]">
                  <div className="flex justify-center items-center">{item.title}</div>
                  <div className="text-kg text-slate-300 mt-4 overflow-y-auto">
                    {item.description}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex justify-center items-center py-2">
          <CircleIconButton onClick={handlePrevious} disabled={currentMobileIndex === 0}>
            <ArrowLeft color={currentMobileIndex === 0 ? '#999999' : '#1F5F8D'} />
          </CircleIconButton>

          <div className="flex gap-2 mx-4">
            {content.map((_, index) => (
              <div
                key={index}
                className={`w-6 h-1 rounded-full ${
                  currentMobileIndex === index ? 'bg-[#1F5F8D]' : 'bg-gray-300'
                }`}
              />
            ))}
          </div>

          <CircleIconButton
            onClick={handleNext}
            disabled={currentMobileIndex === content.length - 1}
          >
            <ArrowRight color={currentMobileIndex === content.length - 1 ? '#999999' : '#1F5F8D'} />
          </CircleIconButton>
        </div>
      </div>
    </Stack>
  )
}
