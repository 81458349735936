import { Box, Container, Grid, Stack, Typography, useTheme } from '@mui/material'

import React from 'react'
import useTrans from '@/hooks/useTrans'

const CardItem = ({
  index,
  title,
  description,
}: {
  index: number
  title: string
  description: string,
}) => {
  return (
    <Stack
      className={
        'group px-6 py-10 relative h-full rounded-xl flex flex-col bg-transparent transition-all duration-500 ease-in-out border-[1.5px] border-solid border-iw-primary-light'
      }
      gap={{ xs: 6, sm: 33 }}
    >
      <Stack
        className={
          'h-full left-0 top-0 transition-all duration-500 ease-in-out rounded-xl opacity-0 group-hover:opacity-100 absolute w-full bg-gradient-to-br from-iw-cobalt-blue to-iw-cobalt-blue-secondary/70 border-[1.5px] border-solid border-iw-primary-light'
        }
      ></Stack>
      <Stack gap={1} className="z-10">
        <Typography className="font-normal text-iw-dark-gray group-hover:text-white text-sm sm:text-base">
          {`0${index + 1}`}
        </Typography>
        <Typography className="font-bold text-iw-neutral-black group-hover:text-white text-2xl sm:text-3xl">
          {title}
        </Typography>
      </Stack>
      <Typography className="z-10 text-iw-dark-gray group-hover:text-white text-base mt-1">
        {description}
      </Typography>
    </Stack>
  )
}

function WhyToChoose() {
  const trans = useTrans()
  const theme = useTheme()
  return (
    <Box className="bg-iw-soft-gray" sx={{ color: theme.palette.grey[600] }}>
      <Container className="relative py-10 sm:py-24 flex flex-col gap-4 items-start">
        <Typography
          className="font-bold font-ma text-start text-iw-charcoal-black text-[30px] leading-[38px] lg:text-[48px] lg:leading-[60px] sm:text-4xl"
          dangerouslySetInnerHTML={{ __html: trans.landing.why_to_choice.title }}
        />
        <Typography className="text-iw-dark-gray text-start md:w-2/3">
          {trans.landing.why_to_choice.description}
        </Typography>
        <Grid container spacing={2} className="mt-0">
          {trans.landing.why_to_choice.items.map(
            (item: { title: string; description: string }, index: number) => (
              <Grid item key={index} md={4} sm={3} xs={12}>
                <CardItem index={index} title={item.title} description={item.description} />
              </Grid>
            ),
          )}
        </Grid>
      </Container>
    </Box>
  )
}

export default WhyToChoose
