import React, { memo, useCallback } from 'react'
import CustomDialog from '@/components/base/CustomDialog'
import { Button, Stack, Typography, Box } from '@mui/material'
import Image from 'next/image'
import useTrans from '@/hooks/useTrans'
import { useRouter } from 'next/router'
import HeroAllSetImage from '@/assets/follow-transaction.png'
import SaleImage from '@/assets/sale.png'
import NorthEastIcon from '@mui/icons-material/NorthEast'

type KycModalProps = {
  open: boolean
  setHasShownKycModal: () => void
}

const KycModal = (props: KycModalProps) => {
  const { open, setHasShownKycModal } = props
  const trans = useTrans()
  const router = useRouter()

  const handleClick = useCallback(() => {
    setHasShownKycModal()
    router.push('/kyc')
  }, [router, setHasShownKycModal])

  return (
    <CustomDialog
      handleClose={setHasShownKycModal}
      isOpened={open}
      body={
        <Stack alignItems="center" className="text-center p-10">
          <Image height={78} src={HeroAllSetImage} alt="hero-all-set" />
          <Typography className="font-ma text-2xl pt-10">
            {trans.landing.hero_all_set.title}
          </Typography>
          <Typography variant="body2" className="py-6">
            {trans.landing.hero_all_set.description}
          </Typography>
          <Box className="p-4 bg-[#fffaeb] rounded-lg border border-[#fedf89]">
            <Stack direction="row" alignItems="center" justifyContent="space-between" gap={0.5}>
              <Image width={46} src={SaleImage} alt="SaleImage" />
              <Typography className="text-gray-800 font-inter text-sm">
                {trans.landing.hero_all_set.complete_kyc}
              </Typography>
              <NorthEastIcon sx={{ color: '#F79009' }} />
            </Stack>
          </Box>
          <Stack gap={1} className="w-full pt-10">
            <Button variant="contained" onClick={handleClick}>
              {trans.landing.hero_all_set.kyc_now}
            </Button>
            <Button variant="text" onClick={setHasShownKycModal}>
              {trans.landing.hero_all_set.later}
            </Button>
          </Stack>
        </Stack>
      }
    />
  )
}

export default memo(KycModal)
