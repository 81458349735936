'use client'
import React from 'react'
import useTrans from '@/root/src/hooks/useTrans'
import Image from 'next/image'
import { IconName, staticImportIcon } from '@/root/src/utils/static-import-icon'
import { Box, Container, Stack, Typography } from '@mui/material'
export function Partners() {
  const trans = useTrans()

  return (
    <Container className="relative py-10 sm:py-24">
      <Stack className="mb-5">
        <Typography className="font-bold font-ma text-center text-iw-charcoal-black text-[30px] sm:text-5xl lg:leading-[60px] leading-[38px] max-w-[700px] mx-auto">
          {trans.landing.partners.title}
        </Typography>
      </Stack>
      <Typography className="mb-16 text-center text-iw-charcoal-black max-w-[728px] mx-auto">
        {trans.landing.partners.description}
      </Typography>
      <Box className="flex flex-wrap justify-center gap-8">
        <Box className="w-[184px] h-[70px] bg-[#F9FAFB] rounded-md flex items-center justify-center">
          <Image src={staticImportIcon('renec-logo-white')} alt={IconName.RENEC} />
        </Box>
        <Box className="w-[184px] h-[70px] bg-[#F9FAFB] rounded-md flex items-center justify-center">
          <Image src={staticImportIcon(IconName.REMITANO)} alt={IconName.REMITANO} />
        </Box>
        <Box className="w-[184px] h-[70px] bg-[#F9FAFB] rounded-md flex items-center justify-center">
          <Image src={staticImportIcon(IconName.BRIDGE)} alt={IconName.BRIDGE} />
        </Box>
      </Box>
    </Container>
  )
}
