import CountUp from 'react-countup'

interface CountUpProps {
    number: number;
}

export const DefaultCountUp = ({ number}: CountUpProps) => {
  return (
    <CountUp className="font-bold text-iw-secondary" end={number} />
  )
}

