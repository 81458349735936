import { IconName } from '@/utils/static-import-icon'

export const socials = (onlyIcon?: boolean) => [
  {
    name: 'Facebook',
    url: 'https://www.facebook.com/instantwire',
    icon: IconName.FACEBOOK_WHITE_ICON,
    onlyIcon: onlyIcon,
  },
  {
    name: 'Twitter',
    url: 'https://twitter.com/InstantWireInfo',
    icon: IconName.X_WHITE,
    onlyIcon: onlyIcon,
  },
  {
    name: 'Instagram',
    url: 'https://www.instagram.com/instantwire_info/',
    icon: IconName.INSTAGRAM_WHITE,
    onlyIcon: onlyIcon,
  },
]
