import React from 'react'

import Image from 'next/image'

import { IconName, staticImportIcon } from '@/root/src/utils/static-import-icon'
import { Link } from '@mui/material'

const LogoFooter = () => {
  return (
    <Link href="/" data-testid="header-home-trade" className="no-underline flex items-center">
      <Image
        className="inline mr-2"
        src={staticImportIcon(IconName.LOGO_FOOTER)}
        height={44}
        alt={IconName.LOGO_FOOTER}
      />
      <Image
        className="inline"
        src={staticImportIcon(IconName.INSTANTWIRE_TEXT_FOOTER)}
        height={20.62}
        alt={IconName.INSTANTWIRE_TEXT_FOOTER}
      />
    </Link>
  )
}

export default LogoFooter
