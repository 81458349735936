import { MouseEvent, useEffect, useState } from 'react'

import { signIn } from 'next-auth/react'
import { useSearchParams } from 'next/navigation'
import { useTotalUsers } from '@/root/src/hooks/use-total-users'
import { IconName, staticImportIcon } from '@/root/src/utils/static-import-icon'
import { Button, Container, Stack, Typography } from '@mui/material'
import { DefaultCountUp } from '@/components/shared/count-up'
import HeroWorldMap from '@/assets/hero-world-map.png'

import Image from 'next/image'
import WaitListAvatars from '@/components/shared/waitlist-avatars'
import useTrans from '@/hooks/useTrans'
import useMobileDetection from '@/root/src/hooks/use-mobile-detection'
import { retrieveOrGenerateUUID } from '@/utils/helpers'

function HeroWaitlist() {
  const trans = useTrans()
  const fakeUserCount = 868
  const [totalUsers, setTotalUsers] = useState(fakeUserCount)
  const searchParams = useSearchParams()
  const isMobile = useMobileDetection()

  const { data: dataTotalUsers } = useTotalUsers()

  useEffect(() => {
    if (dataTotalUsers && dataTotalUsers > 0) setTotalUsers(dataTotalUsers + fakeUserCount)
  }, [dataTotalUsers])

  const onLoginGoogle = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    signIn('google', {
      redirect: true,
      callbackUrl: searchParams.get('callback_url') || '/',
      loginUuid: retrieveOrGenerateUUID(),
    })
  }

  return (
    <Container className="relative lg:py-16 py-8 md:px-44 sm:px-20">
      <Image
        className="absolute mt-40 sm:mt-32 inset-0 m-auto opacity-20"
        height={391}
        src={HeroWorldMap}
        alt="hero-wait-list"
      />
      <Stack alignItems="center" className="relative text-iw-dark-gray text-center" gap={3}>
        <Stack className="relative">
          <Typography
            className="font-bold font-ma text-[2rem] text-iw-neutral-black sm:font-extrabold sm:text-5xl leading-[38px] lg:leading-[60px]"
            dangerouslySetInnerHTML={{ __html: trans.landing.hero_waitlist.title(isMobile) }}
          />
        </Stack>

        <Typography
          variant="body2"
          className="w-full sm:w-3/4 md:w-2/3"
          dangerouslySetInnerHTML={{ __html: trans.landing.hero_waitlist.description }}
        />

        <Button
          className="group bg-white border-1.5 border-solid border-iw-primary hover:border-iw-secondary  px-10 py-3 sm:px-20 mt-4 flex items-center gap-6"
          onClick={onLoginGoogle}
        >
          <Image
            alt={IconName.GOOGLE_LOGO}
            width={32}
            height={32}
            src={staticImportIcon(IconName.GOOGLE_LOGO)}
          />
          <Typography className="font-semibold text-base text-[#1e5e8d] group-hover:text-iw-secondary">
            {trans.landing.hero_waitlist.join_wait_list_gmail}
          </Typography>
        </Button>

        <Stack direction="row" alignItems="center" gap={1} className="mt-10 px-10">
          <WaitListAvatars />
          <Typography variant="body2" className="text-left sm:text-center flex-1">
            <DefaultCountUp number={totalUsers} />
            &nbsp;
            {trans.landing.hero_waitlist.users_on_the_wait_list}
          </Typography>
        </Stack>
      </Stack>
    </Container>
  )
}

export default HeroWaitlist
