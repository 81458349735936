import React, { memo } from 'react'

import { Box, Container, Grid, Stack, Typography, useTheme } from '@mui/material'

import FooterCompany from '../components/FooterCompany'
import FooterInstantwire from '../components/FooterInstantwire'
import FooterSocial from '../components/FooterSocial'
import ScrollToTopButton from '@/components/base/ScrollToTopButton'

const Footer = () => {
  const theme = useTheme()

  return (
    <Box className="bg-iw-charcoal-black m-2 rounded-lg">
      <Container className="mt-8 sm:mt-4">
        <Grid container className="mb-6 sm:my-14" spacing={{ xs: 4, sm: 1 }}>
          <Grid item xs={12} sm={4} md={5}>
            <FooterInstantwire />
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <FooterCompany />
          </Grid>
          <Grid item xs={12} sm={2} md={3} sx={{ marginLeft: 'auto' }}>
            <FooterSocial />
          </Grid>
        </Grid>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="pt-4 pb-8 sm:pb-24"
          spacing={{ xs: 2, sm: 0 }}
          sx={{
            borderTop: `1px solid ${theme.palette.grey[200]}`,
          }}
        >
          <Typography className="text-sm text-white">
            © 2024 Instantwire. All rights reserved.
          </Typography>
          <ScrollToTopButton />
        </Stack>
      </Container>
    </Box>
  )
}

export default memo(Footer)
