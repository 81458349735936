import Avatar from '@mui/material/Avatar'
import AvatarGroup from '@mui/material/AvatarGroup'

function WaitListAvatars() {
  return (
    <AvatarGroup classes={{ avatar: '-ml-4 h-8 w-8' }} className='w-fit'>
      <Avatar alt="Remy Sharp" src="/images/avatars/3.jpg" className="z-10" />
      <Avatar alt="Travis Howard" src="/images/avatars/2.jpg" className="z-20" />
      <Avatar alt="Cindy Baker" src="/images/avatars/1.jpg" className="z-30" />
    </AvatarGroup>
  )
}

export default WaitListAvatars
