import useIwMaintenance from '@/hooks/use-iw-maintenance'
import View from './view'

const HomePage = () => {
  /**
   * Check if the maintenance mode is enabled
   */
  useIwMaintenance()

  return <View />
}

HomePage.displayName = 'HomeContainer'
export default HomePage
