import { memo } from 'react'
import { Stack, Typography } from '@mui/material'
import useTrans from '@/hooks/useTrans'
import LogoFooter from '@/root/src/components/base/LogoFooter'

function FooterInstantwire() {
  const trans = useTrans()
  return (
    <Stack data-testid="social-links-wrapper" direction="column" spacing={1}>
      <LogoFooter />

      <Typography
        data-testid="footer-title"
        className="font-semibold font-ma text-xl not-italic text-white pr-0 md:pr-5 mt-5"
      >
        {trans.footer.title}
      </Typography>
      <Typography
        data-testid="footer-description"
        className="text-sm not-italic text-white pr-0 md:pr-5"
      >
        {trans.footer.description}
      </Typography>
    </Stack>
  )
}

export default memo(FooterInstantwire)
