'use client'
import React from 'react'
import { Carousel } from '@/root/src/components/base/carousel'
import useTrans from '@/root/src/hooks/useTrans'
import Image from 'next/image'
import { staticImportIcon } from '@/root/src/utils/static-import-icon'
import { Container, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
export function StepsToFollow() {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const trans = useTrans()
  const [activeStep, setActiveStep] = React.useState(0)

  const mapContent = (stepIndex: any) => {
    return (
      <div className="h-full w-full">
        {trans.landing.steps_to_follow.steps.map((step: any, index: any) => (
          <div key={index} className="flex items-center gap-2 mb-4">
            {stepIndex === index && <div className="w-[40px] h-1 rounded-sm bg-iw-primary"></div>}
            <div className={stepIndex === index ? 'text-iw-primary font-[600]' : ''}>
              {step.title}
            </div>
          </div>
        ))}
      </div>
    )
  }
  const content = [
    ...trans.landing.steps_to_follow.steps.map((step: any, index: any) => ({
      title: (
        <div className="w-full aspect-square flex justify-center">
          <Image
            alt={step.image}
            className="lg:w-[816px] lg:h-[507px] w-full h-full rounded-xl object-cover"
            src={staticImportIcon(step.image)}
          />
        </div>
      ),
      description: (
        <div>
          <Typography className="text-iw-charcoal-black text-xl font-bold">{step.title}</Typography>
          <Typography className="text-iw-dark-gray text-base">{step.description}</Typography>
        </div>
      ),
      content: mapContent(index),
    })),
  ]

  const DesktopView = () => {
    return (
      <div className="flex gap-8 p-6 rounded-xl border border-[#C5DFF2]">
        <div className="w-[58%] relative">
          <Image
            alt={trans.landing.steps_to_follow.steps[activeStep].image}
            className="w-full h-full object-cover rounded-2xl absolute inset-0"
            src={staticImportIcon(trans.landing.steps_to_follow.steps[activeStep].image)}
          />
        </div>

        <div className="w-[42%]">
          {trans.landing.steps_to_follow.steps.map((step: any, index: number) => (
            <div
              key={index}
              className={`p-4 cursor-pointer transition-all duration-300 rounded-md ${
                activeStep === index ? 'bg-[#F4FAFE]' : 'hover:bg-white'
              }`}
              onMouseEnter={() => setActiveStep(index)}
            >
              <Typography className={'font-bold mb-2 text-iw-charcoal-black'}>
                {step.title}
              </Typography>
              <Typography className="text-iw-dark-gray text-sm">{step.description}</Typography>
            </div>
          ))}
        </div>
      </div>
    )
  }

  return (
    <Container className="relative py-10 sm:py-24">
      <Stack className="mb-10">
        <Typography className="mb-4 font-bold font-ma text-start text-iw-charcoal-black text-[30px] sm:text-5xl">
          {trans.landing.steps_to_follow.title}
        </Typography>
      </Stack>
      {isMobile ? <Carousel content={content} /> : <DesktopView />}
    </Container>
  )
}
